import { getSaleOrderChangeList,batchResetSaleOrderChange,exportStock } from "./api.js";
import navList from "./navList";
import Util from "@/utils/utils";

export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/salesOrderChange/list",
          name: "salesOrderChangeList",
          title: "信小蜜销量转换明细"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      scroll: {
        // x: 'true',
        // y: 400
      },
      columns: [
        {
          label: "门店",
          prop: "storeName",
          ellipsis: true,
          key: "storeName",
          width: 180
        },
        {
          label: "销售日期",
          prop: "documentDateStr",
          ellipsis: true,
          key: "documentDateStr",
          width: 160
        },
        {
          label: "销售员",
          prop: "sellerName",
          ellipsis: true,
          key: "sellerName",
          width: 120
        },
        {
          label: "客户",
          prop: "customerName",
          ellipsis: true,
          key: "customerName",
          width: 100
        },
        {
          label: "产品编码",
          prop: "productCode",
          ellipsis: true,
          key: "productCode",
          width: 200
        },
        {
          label: "型号",
          prop: "model",
          ellipsis: true,
          key: "model",
          width: 100
        },
        {
          label: "单价",
          prop: "priceSetCode",
          ellipsis: true,
          align:'right',
          key: "priceSetCode",
          width: 100
        },
        {
          label: "数量",
          prop: "salesQty",
          ellipsis: true,
          align:'right',
          key: "salesQty",
          width: 100
        },
        {
          label: "标志",
          prop: "isSyn",
          ellipsis: true,
          key: "isSyn",
          width: 90
        },
        {
          label: "转换结果",
          prop: "message",
          ellipsis: true,
          key: "message",
          width: 120
        }
      ],
      data: [],
      terms: {},
      selRows:[],
      selectedRowKeys:[],
      showLoading:false
    };
  },
  created() {
    this.navList = navList.nav1;
    // this.getData();
  },
  methods: {
    resetBat() {
      if (this.selRows.length != 1) {
        this.$message.warning("请先选择至少一条数据！");
        return;
      }
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        ids:this.selRows.map(it=>it.id)
      }
      batchResetSaleOrderChange(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.code==0){
          this.$message.success("批量重置成功！");
        }else{
          this.$message.warning(data.msg);
        }
      })
    },
    //行选中，取消
    onChange( selRows) {
      this.selRows = selRows;
      this.selectedRowKeys = selRows.map(it=>it.id);
    },
    searchFun(arg) {
      this.terms = arg[1];
      this.pager.pageNo = 1
      this.getData();
    },
    //排序change事件
    onTableChange(pagination, filters, sorter, currentDataSource) {
      if (sorter && sorter.order) {
        this.pager.sortOrder =
          sorter.order == "ascend"
            ? "asc"
            : sorter.order == "descend"
            ? "desc"
            : "";
        this.pager.sortName = sorter.column.sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'销量转换列表.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
  },
    getData() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      this.showLoading = true
      getSaleOrderChangeList(data).then(res => {
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        // const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        data.data = data.data || [];
        this.showLoading = false
        // this.data = data.data.slice(start, start + this.pager.pageSize);
        this.data = data.data;
        // this.pager.count = data.data.length;
        this.pager.count = data.page.totalCount;
      });
    }
  }
};
