import dmshttp from "@/utils/dmsrequest";

//销量转换列表
export function getSaleOrderChangeList(data) {
    return dmshttp({
        url: "getSaleOrderChangeList",
        data
    });
}
//批量重置接口
export function batchResetSaleOrderChange(data) {
    return dmshttp({
        url: "batchResetSaleOrderChange",
        data
    });
}
export function exportStock(data) {
    return dmshttp({
        url: "exportSaleOrderChangeList&type=1",
        data,
        responseType: 'blob'
    });
}